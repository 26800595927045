import { Controller } from "stimulus"
import $ from 'jquery'

export default class extends Controller {
  static targets = [ "headSpecialistsCard", "vocDepartment", "headCheckbox" ]

  connect() {
    this.toggleHeadSpecialistsCard(
      this.data.has('roles') && (
        this.data.get('roles') === 'head_specialist'
      )
    )

    this.toggleMemberFields(
      this.data.has('roles') && this.data.get('roles') === 'commission_member'
    )
  }

  doRoleSelect(event) {
    const roleId = event.target.value
    this.toggleHeadSpecialistsCard(roleId === '2')
    this.toggleMemberFields(roleId === '4')
  }

  toggleHeadSpecialistsCard(isHS) {
    if (isHS) {
      $(this.headSpecialistsCardTarget).show()
    } else {
      $(this.headSpecialistsCardTarget).hide()
    }
  }

  toggleMemberFields(isMember) {
    if (isMember) {
      $(this.vocDepartmentTarget).show();
      $(this.headCheckboxTarget).show();
    } else {
      $(this.vocDepartmentTarget).hide();
      $(this.headCheckboxTarget).hide();
    }
  }
}
